import React, {useState, useEffect, useRef} from 'react';
import './Thread.scss';

import {useQueryParam, StringParam} from "use-query-params";
import parse from 'html-react-parser';
import moment from 'moment-timezone';
import Swal from 'sweetalert2';
import {navigate} from 'gatsby';
import {
  getThread,
  voteThread,
  voteThreadReply,
  replyThread,
  reportThread,
  reportThreadReply,
  getThreadReplies,
} from '@repository/ThreadRepository';
import {getMe} from '@repository/UserRepository';
import DefaultProfilePicture from '@backgrounds/cerebro_default_pp.png';
import {getToken} from "@repository/client";

const isLogined = getToken() ? true : false;

function Comment(props) {
  const [replyUpVote, setReplyUpVote] = useState(props.votesUpCount);
  const [replyDownVote, setReplyDownVote] = useState(props.votesDownCount);
  const [myVote, setMyVote] = useState(props.myVote);

  const handleVoteThreadReply = (replyId, type) => {
    if (type === 'down') {
      if (myVote === 'up') {
        setReplyUpVote((prev) => prev - 1);
        setReplyDownVote((prev) => prev + 1);
      } else if (myVote === 'down') {
        setReplyDownVote((prev) => prev - 1);
      } else {
        setReplyDownVote((prev) => prev + 1);
      }
    } else if (type === 'up') {
      if (myVote === 'up') {
        setReplyUpVote((prev) => prev - 1);
      } else if (myVote === 'down') {
        setReplyUpVote((prev) => prev + 1);
        setReplyDownVote((prev) => prev - 1);
      } else {
        setReplyUpVote((prev) => prev + 1);
      }
    }
    let myVoteOldValue = myVote;
    if (type === myVote) {
      setMyVote(null);
    } else {
      setMyVote(type);
    }
    voteThreadReply(replyId, type)
      .then((res) => {
        
      })
      .catch((err) => {
        if (type === 'down') {
          if (myVoteOldValue === 'up') {
            setReplyUpVote((prev) => prev + 1);
            setReplyDownVote((prev) => prev - 1);
          } else if (myVoteOldValue === 'down') {
            setReplyDownVote((prev) => prev + 1);
          } else {
            setReplyDownVote((prev) => prev - 1);
          }
        } else if (type === 'up') {
          if (myVoteOldValue === 'up') {
            setReplyUpVote((prev) => prev + 1);
          } else if (myVoteOldValue === 'down') {
            setReplyUpVote((prev) => prev - 1);
            setReplyDownVote((prev) => prev + 1);
          } else {
            setReplyUpVote((prev) => prev - 1);
          }
        }
        setMyVote(myVoteOldValue);
        console.log(err.response);
      })
  };

  const handleReportThread = (id) => {
    reportThreadReply(id)
      .then((res) => {
        Swal.fire(
          'Success',
          'You have submitted your report!',
          'success'
        );
      })
      .catch((err) => {
        Swal.fire(
          'Error',
          err.response.data.message,
          'error'
        );
        console.log(err.response.data.message);
      });
  };

  return (
    <div className="comment mt-4">
      <div className="desktop-version d-none d-md-block">
        <div className="d-flex align-items-center mb-2">
          <div
            className={`rounded-circle profile-picture mr-2 ${props.profilePicture ? 'bg-pure-white' : 'bg-harbor-gray'}`}
            style={{backgroundImage: `url(${props.profilePicture ? props.profilePicture : DefaultProfilePicture})`}}
          />
          <div className="flex-grow-1">
            <div className="d-flex align-items-center">
              <div className="asap-body mr-1">{props.name}</div>
              <img src={props.flagImage} className="mx-2 image-flag" />
              <div className="rounded-circle bg-harbor-gray mx-2 dotted" />
              <div className="asap-caption text-harbor-gray">
                {props.time}
              </div>
            </div>
            <div className="asap-caption text-steel-gray">{props.university}</div>
          </div>
          <div className="d-flex align-items-center cursor-pointer" onClick={() => handleVoteThreadReply(props.id, "up")}>
            <div className="mdi mdi-arrow-up-bold-outline mdi-18px text-jade-green mr-1" />
            <div className="asap-body text-jade-green mr-2">{replyUpVote}</div>
          </div>
          <div className="d-flex align-items-center cursor-pointer" onClick={() => handleVoteThreadReply(props.id, "down")}>
            <div className="mdi mdi-arrow-down-bold-outline mdi-18px text-apple-red mr-1" />
            <div className="asap-body text-apple-red">{replyDownVote}</div>
          </div>
          <div className="dropdown">
            <div className="mdi mdi-dots-vertical text-harbor-gray ml-2 cursor-pointer" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
              <div className="dropdown-item cursor-pointer asap-body text-iron-gray" onClick={() => handleReportThread(props.id)}>Report</div>
            </div>
          </div>
        </div>
        <div className="asap-body">
          {props.body}
        </div>
      </div>
      <div className="mobile-version d-block d-md-none">
        <div className="d-flex flex-row align-items-center justify-content-between">
          <div className="asap-caption text-harbor-gray">
            {props.time}
          </div>
          <div className="d-flex align-items-center">
            <div className="d-flex align-items-center cursor-pointer" onClick={() => handleVoteThreadReply(props.id, "up")}>
              <div className="mdi mdi-arrow-up-bold-outline mdi-18px text-jade-green mr-1" />
              <div className="asap-body text-jade-green mr-2">{replyUpVote}</div>
            </div>
            <div className="d-flex align-items-center cursor-pointer" onClick={() => handleVoteThreadReply(props.id, "down")}>
              <div className="mdi mdi-arrow-down-bold-outline mdi-18px text-apple-red mr-1" />
              <div className="asap-body text-apple-red">{replyDownVote}</div>
            </div>
            <div className="dropdown">
              <div className="mdi mdi-dots-vertical text-harbor-gray ml-2 cursor-pointer" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
              <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                <div className="dropdown-item cursor-pointer asap-body text-iron-gray" onClick={() => handleReportThread(props.id)}>Report</div>
              </div>
            </div>
          </div>
        </div>
        <div className="divider w-100 bg-abalone-gray mt-1 mb-3" />
        <div className="d-flex align-items-center mb-2">
          <div
            className={`rounded-circle profile-picture mr-2 ${props.profilePicture ? 'bg-pure-white' : 'bg-harbor-gray'}`}
            style={{backgroundImage: `url(${props.profilePicture ? props.profilePicture : DefaultProfilePicture})`}}
          />
          <div className="flex-grow-1">
            <div className="d-flex align-items-center">
              <div className="asap-body mr-1">{props.name}</div>
              <img src={props.flagImage} className="mx-2 image-flag" />
            </div>
            <div className="asap-caption text-steel-gray">{props.university}</div>
          </div>
        </div>
        <div className="asap-body">
          {props.body}
        </div>
      </div>
    </div>
  )
}

function Replies({threadId, parentId, profilePicture}) {
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [isLoadReplies, setIsLoadReplies] = useState(false);
  const [replies, setReplies] = useState([]);
  const [isShowReply, setIsShowReply] = useState(false);
  const [isReplying, setIsReplying] = useState(false);
  const [threadReply, setThreadReply] = useState('');
  const newReplyRef = useRef(null);
  const replyRef = useRef(null);

  useEffect(() => {
    handleGetReplies();
  }, [currentPage]);

  useEffect(() => {
    if (!isShowReply) {
      return;
    }
    if (replies.length === 0) {
      newReplyRef.current.focus();
    } else {
      replyRef.current.focus();
    }
  }, [isShowReply]);

  const handleGetReplies = () => {
    setIsLoadReplies(true);
    getThreadReplies(threadId, parentId, currentPage, 3)
      .then((res) => {
        setIsLoadReplies(false);
        setReplies((prevValue) => {
          let prevValueIds = prevValue.filter((_reply) => _reply._id);
          let _replies = res.data.data.filter((reply) => !prevValueIds.includes(reply._id));
          return [
            ..._replies,
            ...prevValue,
          ]
        });
        setLastPage(res.data.meta.last_page);
      })
      .catch((err) => {
        setReplies([]);
        setLastPage(1);
        setCurrentPage(1);
        console.log(err.response);
      });
  };

  const handleReportThread = (id) => {
    reportThreadReply(id)
      .then((res) => {
        Swal.fire(
          'Success',
          'You have submitted your report!',
          'success'
        );
      })
      .catch((err) => {
        Swal.fire(
          'Error',
          err.response.data.message,
          'error'
        );
        console.log(err.response);
      });
  };

  const Reply = (props) => (
    <div className="mb-3 mt-2">
      <div className="d-flex align-items-center mb-2">
        <div
          className={`rounded-circle profile-picture mr-2 ${profilePicture ? 'bg-pure-white' : 'bg-harbor-gray'}`}
          style={{backgroundImage: `url(${profilePicture ? profilePicture : DefaultProfilePicture})`}}
        />
        <div className="flex-grow-1">
          <div className="d-flex align-items-center">
            <div className="asap-body mr-1">{props.name}</div>
            <img src={props.flagImage} className="mx-2 image-flag" />
            <div className="rounded-circle bg-harbor-gray mx-2 dotted" />
            <div className="asap-caption text-harbor-gray">
              {props.time}
            </div>
          </div>
          <div className="asap-caption text-steel-gray">{props.university}</div>
        </div>
        <div className="dropdown">
          <div className="mdi mdi-dots-vertical text-harbor-gray ml-2 cursor-pointer" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
          <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
            <div className="dropdown-item cursor-pointer asap-body text-iron-gray" onClick={() => handleReportThread(props.id)}>Report</div>
          </div>
        </div>
      </div>
      <div className="asap-body">{props.body}</div>
    </div>
  );

  const handleReplyThread = () => {
    setIsReplying(true);
    replyThread(threadId, threadReply, parentId)
      .then((res) => {
        let newReply = res.data.data;
        if (replies) {
          setReplies((currentValue) => ([
            newReply,
            ...currentValue,
          ]))
        } else {
          setReplies([newReply]);
        }
        setIsShowReply(false);
        setIsReplying(false);
        setThreadReply("");
      })
      .catch((err) => {
        setIsReplying(false);
        console.log(err.reponse);
      })
  };

  return (
    <div className="mt-3 pl-2 ml-2 pl-sm-3 ml-sm-3 py-1 replies">
      {replies.length === 0 && (
        <>
          {isLogined  && (
            <div className="asap-body text-steel-gray">
              No replies yet. <u className="text-azure-blue cursor-pointer" onClick={() => {
                setIsShowReply((prevValue) => !prevValue);
              }}>Add your replies</u> for this answer
            </div>
          )}
          {isShowReply && (
            <div className="bg-pearl-gray rounded p-3 mt-3">
              <div className="d-flex">
                <div
                  className={`rounded-circle mr-2 mt-2 profile-picture ${profilePicture ? 'bg-pure-white' : 'bg-harbor-gray'}`}
                  style={{backgroundImage: `url(${profilePicture ? profilePicture : DefaultProfilePicture})`}} />
                <div className="flex-grow-1">
                  <div className={`form-group d-flex flex-column-reverse flex-grow-1`}>
                    <textarea
                      ref={newReplyRef}
                      className="form-control"
                      placeholder="Write your reply here..."
                      onChange={(e) => setThreadReply(e.target.value)}
                      value={threadReply}
                      focus
                      rows="1"
                    />
                  </div>
                </div>
              </div>
              <button
                className="button-sm button-primary"
                onClick={() => handleReplyThread()}
                disabled={isReplying || threadReply.trim().length === 0}>
                {isReplying ? <div className="mdi mdi-loading mdi-spin" /> : "Submit Reply"}
              </button>
            </div>
          )}
        </>
      )}
      {currentPage < lastPage && (
        <div className="d-flex align-items-center cursor-pointer" onClick={() => setCurrentPage((prevValue) => prevValue + 1)}>
          <div className="mdi mdi-chat mdi-18px text-azure-blue mr-1" />
          <div className="asap-body text-azure-blue">
            Load more replies...
          </div>
        </div>
      )}
      {replies && replies.length > 0 && replies.map((reply) => (
        <Reply
          id={reply._id}
          name={reply.user.fullname}
          time={moment.unix(reply.created_at).fromNow()}
          university={reply.user.university.name}
          body={reply.body}
          flagImage={reply.user.country.flag}
        />
      ))}
      {replies.length > 0 && (
        <>
          {isLogined && (
            <div className="asap-body text-steel-gray">
              <u className="text-azure-blue cursor-pointer" onClick={() => setIsShowReply((prevValue) => !prevValue)}>Add your replies</u> for this answer
            </div>
          )}
          {isShowReply && (
            <div className="bg-pearl-gray rounded p-3 mt-3">
              <div className="d-flex">
                <div
                  className={`rounded-circle mr-2 mt-2 profile-picture ${profilePicture ? 'bg-pure-white' : 'bg-harbor-gray'}`}
                  style={{backgroundImage: `url(${profilePicture ? profilePicture : DefaultProfilePicture})`}}
                />
                <div className="flex-grow-1">
                  <div className={`form-group d-flex flex-column-reverse`}>
                    <textarea
                      ref={replyRef}
                      className="form-control"
                      placeholder="Write your reply here..."
                      onChange={(e) => setThreadReply(e.target.value)}
                      value={threadReply}
                      rows="1"
                    />
                  </div>  
                </div>
              </div>
              <button
                className="button-sm button-primary"
                onClick={() => handleReplyThread()}
                disabled={isReplying || threadReply.trim().length === 0}>
                {isReplying ? <div className="mdi mdi-loading mdi-spin" /> : "Submit Reply"}
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
}

function Thread() {

  const [id, setId] = useQueryParam("id", StringParam);
  const [user, setUser] = useState(null);
  const [thread, setThread] = useState(null);
  const [moreThreads, setMoreThreads] = useState(null);
  const [category, setCategory] = useState(null);
  const [isLoadData, setIsLoadData] = useState(true);
  const [isLoadUser, setIsLoadUser] = useState(true);
  const [isAnsweringThread, setIsAnsweringThread] = useState(false);
  const [threadAnswer, setThreadAnswer] = useState("");
  const [isShowAnswer, setIsShowAnswer] = useState(false);
  const [isLoadThreadComments, setIsLoadThreadComments] = useState(false);
  const [threadComments, setThreadComments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [pageNumbers, setPageNumbers] = useState([]);
  const [myVote, setMyVote] = useState(null);

  const answerWrapperRef = useRef(null);
  const answerRef = useRef(null);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    if (id) {
      handleGetThread();
      if (isLogined) {
        handleGetUser();
      } else {
        setIsLoadUser(false);
      }
    } else {
      navigate("/browse");
    }
  }, [id]);

  useEffect(() => {
    if (isShowAnswer) {
      answerWrapperRef.current.scrollIntoView();
      answerRef.current.focus();
    }
  }, [isShowAnswer])

  useEffect(() => {
    handleGetThreadComments();
  }, [currentPage]);

  useEffect(() => {
    generateAndSetPageNumbers();
  }, [lastPage, currentPage]);

  const generateAndSetPageNumbers = () => {
    if (lastPage == 1) {
      setPageNumbers([]);
      return;
    }

    let firstPageNumber = 1;
    if (lastPage <= 5) {
      firstPageNumber = 1;
    } if (currentPage - 2 <= 0) {
      firstPageNumber = 1;
    } else if (currentPage + 2 >= lastPage) {
      firstPageNumber = lastPage - 4;
    } else {
      firstPageNumber = currentPage - 2;
    }

    if (firstPageNumber < 1) firstPageNumber = 1;

    let lastPageNumber = 5;

    if (lastPage <= 5) {
      lastPageNumber = lastPage;
    } else {
      lastPageNumber = firstPageNumber + 4;
    }

    let pageNumbersTemp = [];
    for (let i = firstPageNumber; i <= lastPageNumber; i++) {
      pageNumbersTemp.push(i);
    }
    setPageNumbers(pageNumbersTemp);
  }

  const handleGetThread = () => {
    setIsLoadData(true);
    getThread(id)
      .then((res) => {
        setIsLoadData(false);
        setThread(res.data.thread);
        setMyVote(res.data.thread.my_vote);
        setMoreThreads(res.data.more_threads);
        setCategory(res.data.thread.category);
      })
      .catch((err) => {
        setIsLoadData(false);
        navigate("/browse");
        console.log(err.response);
      })
  };

  const handleGetThreadComments = () => {
    setIsLoadThreadComments(true);
    getThreadReplies(id, null, currentPage, 5)
      .then((res) => {
        setIsLoadThreadComments(false);
        setThreadComments(res.data.data);
        setLastPage(res.data.meta.last_page);
      })
      .catch((err) => {
        setIsLoadThreadComments(false);
        setThreadComments([]);
        setLastPage(1);
        setCurrentPage(1);
        console.log(err.reponse);
      });
  }

  const handleGetUser = () => {
    setIsLoadUser(true);
    getMe()
      .then((res) => {
        setIsLoadUser(false);
        setUser(res.data);
      })
      .catch((err) => {
        setIsLoadUser(false);
        console.log(err.response);
      });
  }

  const OtherArticle = (props) => (
    <div className="d-flex align-items-center mb-2 cursor-pointer other-articles pr-2" onClick={() => navigate(`/thread?id=${props.id}`)}>
      <div className="bg-pure-white mr-3 image" style={{backgroundImage: `url(${props.image})`}} />
      <div className="description-wrapper">
        <div className="asap-caption text-harbor-gray mb-1 title">
          {props.author}
        </div>
        <div className="asap-subhead font-weight-bold description">
          {parse(props.title)}
        </div>
      </div>
    </div>
  );

  const handleVoteThread = (type) => {
    if (type === 'down') {
      if (myVote === 'up') {
        setThread((currentValue) => (
          {
            ...currentValue,
            votes_up_count: currentValue.votes_up_count - 1,
            votes_down_count: currentValue.votes_down_count + 1,
          }
        ));
      } else if (myVote === 'down') {
        setThread((currentValue) => (
          {
            ...currentValue,
            votes_down_count: currentValue.votes_down_count - 1,
          }
        ));
      } else {
        setThread((currentValue) => (
          {
            ...currentValue,
            votes_down_count: currentValue.votes_down_count + 1,
          }
        ));
      }
    } else if (type === 'up') {
      if (myVote === 'up') {
        setThread((currentValue) => (
          {
            ...currentValue,
            votes_up_count: currentValue.votes_up_count - 1,
          }
        ));
      } else if (myVote === 'down') {
        setThread((currentValue) => (
          {
            ...currentValue,
            votes_up_count: currentValue.votes_up_count + 1,
            votes_down_count: currentValue.votes_down_count - 1,
          }
        ));
      } else {
        setThread((currentValue) => (
          {
            ...currentValue,
            votes_up_count: currentValue.votes_up_count + 1,
          }
        ));
      }
    }
    let myVoteOldValue = myVote;
    if (type === myVote) {
      setMyVote(null);
    } else {
      setMyVote(type);
    }
    voteThread(id, type)
      .then((res) => {
      })
      .catch((err) => {
        if (type === 'down') {
          if (myVoteOldValue === 'up') {
            setThread((currentValue) => (
              {
                ...currentValue,
                votes_up_count: currentValue.votes_up_count + 1,
                votes_down_count: currentValue.votes_down_count - 1,
              }
            ));
          } else if (myVoteOldValue === 'down') {
            setThread((currentValue) => (
              {
                ...currentValue,
                votes_down_count: currentValue.votes_down_count + 1,
              }
            ));
          } else {
            setThread((currentValue) => (
              {
                ...currentValue,
                votes_down_count: currentValue.votes_down_count - 1,
              }
            ));
          }
        } else if (type === 'up') {
          if (myVoteOldValue === 'up') {
            setThread((currentValue) => (
              {
                ...currentValue,
                votes_up_count: currentValue.votes_up_count + 1,
              }
            ));
          } else if (myVoteOldValue === 'down') {
            setThread((currentValue) => (
              {
                ...currentValue,
                votes_up_count: currentValue.votes_up_count - 1,
                votes_down_count: currentValue.votes_down_count + 1,
              }
            ));
          } else {
            setThread((currentValue) => (
              {
                ...currentValue,
                votes_up_count: currentValue.votes_up_count - 1,
              }
            ));
          }
        }
        setMyVote(myVoteOldValue);
        console.log(err.response);
      })
  };

  const handleShowAnswerThread = () => {
    if (!isShowAnswer) {
      setIsShowAnswer(true);
    } else {
      answerWrapperRef.current.scrollIntoView();
      answerRef.current.focus();
    }
  }

  const handleAnswerThread = (body, parentId = null) => {
    setIsAnsweringThread(true);
    replyThread(id, body, parentId)
      .then((res) => {
        let newReply = res.data.data;
        setThreadAnswer("");
        setIsAnsweringThread(false);
        if (currentPage !== 1) {
          setCurrentPage(1);
        } else {
          setThreadComments((currentValue) => {
            if (currentValue.length == 5) {
              let _threadComments = currentValue.slice(0, currentValue.length - 1);
              return [
                newReply,
                ..._threadComments,
              ];
            } else {
              return [
                newReply,
                ...currentValue,
              ];
            }
          });
        }
      })
      .catch((err) => {
        console.log(err.reponse);
        setIsAnsweringThread(false);
      })
  }

  const handleReportThread = () => {
    reportThread(id)
      .then((res) => {
        Swal.fire(
          'Success',
          'You have submitted your report!',
          'success'
        );
      })
      .catch((err) => {
        Swal.fire(
          'Error',
          err.response.data.message,
          'error'
        );
        console.log(err.reponse);
      });
  }

  const lowercaseFirstLetter = (text) => {
    return text.charAt(0).toLowerCase() + text.slice(1);
  }

  return (
    <div id="thread" className="container py-5 d-flex flex-column flex-grow-1">
      {isLoadData || isLoadUser ? (
        <div className="mdi mdi-loading mdi-spin mdi-48px m-auto" />
      ) : thread && category && (!isLogined || user) ? (
        <div className="row">
          <div className="col-lg-7 mb-4 mb-lg-0">
            <div className="d-flex flex-column profile-wrapper pb-2 mb-3">
              <div className="d-flex flex-row align-items-center flex-grow-1">
                <div
                  className={`rounded-circle mr-3 image ${thread.user.profile_picture_url ? 'bg-pure-white' : 'bg-harbor-gray'}`}
                  style={{
                    backgroundImage:
                      `url(${thread.user.profile_picture_url ? thread.user.profile_picture_url : DefaultProfilePicture})`
                  }}
                />
                <div className="mr-auto mb-2 mb-sm-0">
                  <div className="d-flex align-items-center mb-1">
                    <div className="asap-subhead">
                      {thread.user.fullname.length < 13 ? thread.user.fullname : thread.user.fullname.substring(0, 13) + '...'}
                    </div>
                    <img src={thread.user.country.flag} className="mx-2 image-flag" />
                    {thread.user.username === 'admin' && (
                      <div className="mdi mdi-check-decagram text-azure-blue" />
                    )}
                  </div>
                  <div className="d-flex flex-column">
                    <div className="asap-caption text-steel-gray">{thread.user.username}</div>
                    {/* <div className="rounded-circle bg-steel-gray mx-2 dotted" /> */}
                    <div className="asap-caption text-steel-gray">{thread.user.university.name}</div>
                  </div>
                </div>
                {isLogined && (
                  <div className="button-primary button-sm d-sm-block d-none" onClick={handleShowAnswerThread}>Answer Thread</div>
                )}
                <div className="dropdown">
                  <div className="mdi mdi-dots-vertical text-harbor-gray ml-2 cursor-pointer" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                  <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                    <div className="dropdown-item cursor-pointer asap-body text-iron-gray" onClick={handleReportThread}>Report</div>
                  </div>
                </div>
              </div>
              {isLogined && (
                <div className="button-primary text-center button-sm d-sm-none d-block mt-2" onClick={handleShowAnswerThread}>Answer Thread</div>
              )}
            </div>
            <div className="d-flex align-items-center mb-3">
              <div className="asap-caption text-steel-gray">{moment.unix(thread.created_at).fromNow()}</div>
              <div className="rounded-circle bg-steel-gray mx-2 dotted" />
              <div
                onClick={() => navigate(`/browse?categoryid=${category._id}&categorytitle=${category.title}`)}
                className="asap-caption text-azure-blue cursor-pointer">
                {category.title}
              </div>
              <div className="rounded-circle bg-steel-gray mx-2 dotted" />
              <div className="d-flex align-items-center cursor-pointer" onClick={() => handleVoteThread("up")}>
                <div className="mdi mdi-arrow-up-bold-outline mdi-18px text-jade-green mr-1" />
                <div className="asap-body text-jade-green mr-2">{thread.votes_up_count}</div>
              </div>
              <div className="d-flex align-items-center cursor-pointer" onClick={() => handleVoteThread("down")}>
                <div className="mdi mdi-arrow-down-bold-outline mdi-18px text-apple-red mr-1" />
                <div className="asap-body text-apple-red">{thread.votes_down_count}</div>
              </div>
            </div>
            <div className="dosis-title-1 mb-2">{thread.title}</div>
            {thread.image_url && (
              <div
                className={`cover-image bg-pure-white w-100 mb-3`}
                style={{backgroundImage: `url(${thread.image_url})`}}
              />
            )}
            <div className="asap-body mb-4">
              {parse(thread.body)}
            </div>
            <div className="d-flex align-items-center" ref={answerWrapperRef}>
              <div className="mdi mdi-chat mdi-18px text-harbor-gray mr-1" />
              <div className="asap-body text-iron-gray">
                Answers <b>({threadComments.length})</b>
              </div>
            </div>
            <div className="divider w-100 bg-abalone-gray mt-2 mb-3" />
            {isShowAnswer && (
              <div className="bg-pearl-gray p-3 answer-wrapper">
                <div className="dosis-headline mb-2">Answer Thread</div>
                <div className="d-flex">
                  <div
                    className={`rounded-circle mr-2 mt-2 profile-picture ${user.profile_picture_url ? 'bg-pure-white' : 'bg-harbor-gray'}`}
                    style={{backgroundImage: `url(${user.profile_picture_url ? user.profile_picture_url : DefaultProfilePicture})`}}
                  />
                  <div className="flex-grow-1">
                    <div className={'form-group d-flex flex-column-reverse flex-grow-1'}>
                      <textarea
                        ref={answerRef}
                        className="form-control"
                        placeholder="Write your answer here..."
                        value={threadAnswer}
                        onChange={(e) => setThreadAnswer(e.target.value)}
                        rows="1"
                        required
                      />
                    </div>
                    <button
                      className="button-sm button-primary"
                      disabled={threadAnswer.trim().length === 0 || isAnsweringThread}
                      onClick={() => handleAnswerThread(threadAnswer)}>
                      {isAnsweringThread ? <div className="mdi mdi-loading mdi-spin" /> : "Send Message"}
                    </button>
                  </div>
                </div>
              </div>
            )}
            {isLoadThreadComments ? (
              <div className="mdi mdi-loading mdi-spin mdi-48px text-center py-2" />
            ) : (
              <>
                {threadComments.length > 0 && (
                  <>
                    {threadComments.map((threadComment) => {
                      return (
                        <div key={threadComment._id}>
                          <Comment
                            id={threadComment._id}
                            name={threadComment.user.fullname}
                            time={moment.unix(threadComment.created_at).fromNow()}
                            university={threadComment.user.university.name}
                            body={threadComment.body}
                            profilePicture={threadComment.user.profile_picture_url}
                            flagImage={threadComment.user.country.flag}
                            votesUpCount={threadComment.votes_up_count}
                            votesDownCount={threadComment.votes_down_count}
                            myVote={threadComment.my_vote}
                          />
                          <Replies
                            threadId={id}
                            parentId={threadComment._id}
                            profilePicture={threadComment.user.profile_picture_url}
                          />
                        </div>
                      );
                    })}
                  </>
                )}
                <div className={`align-items-center justify-content-center pt-4 ${lastPage <= 1 ? "d-none" : "d-flex"}`}>
                  <div
                    className={`mdi mdi-chevron-double-left mdi-18px cursor-pointer mr-2 ${currentPage === 1 ? "text-abalone-gray" : "text-philadelphia-blue"}`}
                    onClick={() => {
                      if (currentPage !== 1) {
                        setCurrentPage(prevValue => prevValue - 1);
                      }
                    }} />
                  {pageNumbers.map((pageNumber, index) => {
                    return (
                      <div
                        key={`number-${index}`}
                        className={
                          "asap-body mx-2 " +
                          (pageNumber == currentPage ? "text-philadelphia-blue font-weight-bold" : "text-iron-gray cursor-pointer")
                        }
                        onClick={() => {
                          if (pageNumber == currentPage) return;
                          setCurrentPage(pageNumber);
                        }}>
                        {pageNumber}
                      </div>
                    );
                  })}
                  <div
                    className={`mdi mdi-chevron-double-right mdi-18px cursor-pointer mr-2 ${currentPage === lastPage ? "text-abalone-gray" : "text-philadelphia-blue"}`}
                    onClick={() => {
                      if (currentPage !== lastPage) {
                        setCurrentPage(prevValue => prevValue + 1);
                      }
                    }}
                  />
                </div>
              </>
            )}
          </div>
          <div className="col-lg-5">
            {moreThreads && moreThreads.length > 0 && (
              <div className="dosis-headline text-philadelphia-blue mb-3">
                More about {category && lowercaseFirstLetter(category.title)}
              </div>
            )}
            {moreThreads && moreThreads.map((moreThread) => (
              <OtherArticle
                key={moreThread._id}
                id={moreThread._id}
                image={moreThread.image_url ? moreThread.image_url : DefaultProfilePicture}
                title={moreThread.title}
                description={moreThread.body}
                author={moreThread.user.fullname}
              />
            ))}
          </div>
        </div>
      ) : (
        <div>NOT FOUND</div>
      )}
    </div>
  );
}

export default Thread;
